import SiteButton_ShinyButtonInvertedComponent from '@wix/thunderbolt-elements/src/components/SiteButton/viewer/skinComps/ShinyButtonInverted/ShinyButtonInverted.skin';


const SiteButton_ShinyButtonInverted = {
  component: SiteButton_ShinyButtonInvertedComponent
};


export const components = {
  ['SiteButton_ShinyButtonInverted']: SiteButton_ShinyButtonInverted
};


// temporary export
export const version = "1.0.0"
